.share {
    margin-left: 20px;
    display: flex;
    i {
        margin-left: 5px;
    }
}

.share-controls{
    display: flex;
    justify-content: space-between;
    margin: 0px 10px;
}

.share-list {
    margin-top: 10px;
    max-height: 150px;
    overflow: auto;
}