.home {
    display: flex;
    width: 100%;
    height: 100%;
    align-content: space-evenly;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;

    text-shadow: 0px 0px 15px white;

    .home-title {
        font-size: xx-large;
        margin: 30px;
        white-space: nowrap;
    }
}

.login-area {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    transition: width 2s;
    align-items: center;
}

.login-show {
    margin: 30px;
    transition: opacity 2s;
    opacity: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.login-hide {
    margin: 30px;
    transition: opacity 2s;
    opacity: 0%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.signUp-area {
    width: 50%;
    display: flex;
    flex-direction: row;
    transition: width 2s;
    align-items: center;
}

.signup-show {
    margin: 30px;
    transition: opacity 2s;
    opacity: 100%;
    display: flex;
    overflow: hidden;
    justify-content: center;
}

.signup-hide {
    margin: 30px;
    transition: opacity 2s;
    opacity: 0%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected {
    width: 60%;
}

.unselected {
    width: 40%;
}

@media (max-width: 800px) {
    .home {
        flex-direction: column;
    }

    .login-area {
        width: unset;
        height: 50%;
        flex-direction: column;
        transition: height 2s;
    }

    .signUp-area {
        width: unset;
        height: 50%;
        flex-direction: column;
        transition: height 2s;
    }

    .selected {
        width: unset;
        height: 60%;
    }

    .unselected {
        width: unset;
        height: 40%;
    }
}