.login {
    display       : flex;
    flex-direction: column;
    text-shadow   : none;
}


.entry-field {
    display        : flex;
    justify-content: space-between;
    margin-bottom  : 10px;
    > * {
        margin: 0px 10px;
    }
}

.entry-label {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.button {
    background   : none;
    border-radius: 20px;
    color        : white;
    border-color : white;
    font-size    : large;
    border-style : solid;
    padding      : 5px;
    cursor       : pointer;
    transition   : color 1s, border-color 1s;
}

.bad-login {
    color       : red;
    border-color: red;
}

.good-login {
    color       : green;
    border-color: green;
}

.small-button {
    background: none;
    border    : none;
    color     : white;
    padding   : 5px;
    cursor    : pointer;
}