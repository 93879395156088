.album {
    width: 250px;
    height: 250px;
    margin: 15px;
    border-radius: 10px;
    border: white 1px solid;
    position: relative;
    cursor: pointer;

    .album-cover {
        height: 180px
    }
    .album-title {
        text-align: center;
    }
    .album-settings {
        text-align: right;
        margin: 10px;
        position: absolute;
        right: 0;
    }
}