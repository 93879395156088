.album-header {
    display: flex;
}

.album-contents {
    display: flex;
    flex-wrap: wrap;
}

.album-page {
    overflow: scroll;
    height: 100%;
    width: 100%;
}

.album-content {
    width: 250px;
    height: 250px;
    position: relative;
    border: 1px solid white;
    display: flex;

    .make-cover-button {
        position: absolute;
        left: 0;
        top: 0;
        margin: 10px;
        text-shadow: 0px 0px 5px black;
        border:none;
        background: black;
    }    

    .content-button {
        position: absolute;
        right: 0;
        top: 0;
        margin: 10px;
        text-shadow: 0px 0px 5px black;
        border:none;
        background: black;
    }
}

.percent-graphic {
    width: 100%;
    height: 100%;
}

.percent {
    position: absolute;
    align-self: center;
    width: 100%;
    text-align: center;
}

.full-screen-album {
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    left: 0;
    z-index: 0;
    display: flex;
    align-items: center;

    .previous {
        position: absolute;
        left: 0;
        color: white;
        text-shadow: 0px 0px 5px black;
        font-size: xx-large;
        padding: 10px;
    }

    .content-item {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .next {
        position: absolute;
        right: 0;
        color: white;
        text-shadow: 0px 0px 5px black;
        font-size: xx-large;
        padding: 10px;
    }
    .close {
        position: absolute;
        right: 0;
        top: 0;
        color: white;
        text-shadow: 0px 0px 5px black;
        font-size: xx-large;
        padding: 10px;
    }
}