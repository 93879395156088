@import "scss/fontawesome.scss";
@import "scss/solid.scss";

#root {
  background-color: rgb(0, 0, 83);
  color           : white;
  width           : 100vw;
  height          : 100vh;
}

.modal-wrapper {
  background-color: #333333cc;
  position        : fixed;
  width           : 100%;
  height          : 100%;
  top             : 0;
  left            : 0;
  display         : flex;
  align-items     : center;
  justify-content : center;

  .modal {
    position        : relative;
    background-color: rgb(0, 0, 83);
    border-radius   : 15px;
    padding         : 15px;

    .modal-title {
      font-size    : large;
      margin-bottom: 15px;
    }

    .modal-footer {
      display        : flex;
      flex-direction : row;
      justify-content: flex-end;
      padding        : 10px
    }
  }
}

.drop-anchor {
  position: relative;
}

.floaty-drop {
  position        : absolute;
  background-color: rgb(0, 0, 83);
  border          : white;
  border-style    : solid;
  border-width    : 1px;
  top             : 25px;
  width           : 100%;

  ul {
    padding   : 0px;
    list-style: none;
    width     : 100%;
    margin    : 0px;

    li {
      padding: 5px;

      &:hover {
        background-color: rgb(40, 40, 163);
      }
    }
  }
}

.button {
  background   : none;
  border-radius: 20px;
  color        : white;
  font-size    : large;
  border-style : solid;
  padding      : 5px;
  cursor       : pointer;
}

input {
  background   : none;
  color        : white;
  border       : none;
  border-bottom: 1px solid white;
}